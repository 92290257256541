import React, { useState, useEffect } from 'react';
import './InstallAppButton.css'; // Import custom styles

const InstallAppButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setIsVisible(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      setDeferredPrompt(null);
      setIsVisible(false);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="install-app-section">
      <div className="install-app-content">
        <h2>Install Our App</h2>
        <p>Get the best experience by installing our app on your device.</p>
        <button className="install-app-button" onClick={handleInstallClick}>
          Install App
        </button>
      </div>
    </div>
  );
};

export default InstallAppButton;
