// src/utils/fetchProducts.js
import axios from 'axios';
//const fs = require('fs');

export const fetchAndStoreProducts = async () => {
  try {
    const response = await axios.get('https://avinandanserampore.com/api/app_product_api.php');
    const products = response.data;
    ////fs.writeFileSync('data.json', JSON.stringify(response.data, null, 2));
  } catch (error) {
    console.error('Error fetching products:', error);
  }
};
