import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Paper, useMediaQuery } from '@mui/material';
import axios from 'axios';
import banner from '../assets/img/A.png';

const LoginPage = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Media query for responsiveness
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleLogin = async () => {
    try {
      const response = await axios.post('https://avinandanserampore.com/api/login.php', { username, password });
      const { id, customer_id, customer_name, address, GSTIN } = response.data;
      const customerData = { id, customer_id, customer_name, address, GSTIN };
      localStorage.setItem('customer', JSON.stringify(customerData));
      onLogin(customerData);
    } catch (error) {
      alert('Invalid credentials');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      sx={{
        backgroundColor: '#f5f5f5', // Light grey background for contrast
        padding: isSmallScreen ? '1rem' : '2rem', // Adjust padding for smaller screens
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: '2rem',
          width: '100%',
          maxWidth: '400px',
          borderRadius: '8px', // Rounded corners
          textAlign: 'center',
          backgroundColor: 'white', // White background for the form
        }}
      >
        {/* Responsive Banner */}
        <Box
          mb={3}
          sx={{
            backgroundImage: `url(${banner})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: isSmallScreen ? '200px' : '250px', // Adjust height for small screens
            borderRadius: '8px',
          }}
        />

        {/* Login Form */}
        <Typography
          variant={isSmallScreen ? 'h6' : 'h5'}
          gutterBottom
          sx={{ color: '#1976d2', fontWeight: 'bold' }}
        >
          Login
        </Typography>
        <TextField
          label="Username"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          variant="outlined"
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          variant="outlined"
        />
        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
            sx={{
              width: '100%',
              padding: '0.8rem',
              fontSize: isSmallScreen ? '0.9rem' : '1rem', // Adjust font size for smaller screens
            }}
          >
            Login
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default LoginPage;
