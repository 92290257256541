import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Divider, Skeleton, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DiscountIcon from '@mui/icons-material/Discount';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useTheme } from '@mui/material/styles';

const OrderDetail = () => {
  const { orderId } = useParams(); // Get order ID from the URL
  const [orderDetails, setOrderDetails] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0); // State to store the grand total
  const [loading, setLoading] = useState(true); // Loading state for skeleton
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Media query for responsiveness

  // Fetch order details from the backend based on the order ID
  useEffect(() => {
    const fetchOrderDetails = async () => {
      setLoading(true); // Start loading
      try {
        const response = await axios.get('https://avinandanserampore.com/api/get_order_details.php', {
          params: { order_id: orderId },
        });
        setOrderDetails(response.data.order_details); // Set the order details from the API response

        // Calculate the grand total of the order
        const total = response.data.order_details.reduce((sum, detail) => sum + parseFloat(detail.final_amount || 0), 0);
        setGrandTotal(total.toFixed(2)); // Set the grand total state with 2 decimal precision
        setLoading(false); // Stop loading after data is fetched
      } catch (error) {
        console.error('Error fetching order details:', error);
        setLoading(false); // Stop loading if there's an error
      }
    };

    fetchOrderDetails(); // Fetch order details on component mount
  }, [orderId]);

  // Function to calculate scheme discount and final amount
  const calculateDetails = (detail) => {
    const ptr = parseFloat(detail.product_mrp); // Use PTR value from `product_mrp`
    const quantity = parseInt(detail.qty, 10); // Quantity of the product
    const schemeDiscountPercentage = parseFloat(detail.discount_percentage) || 0; // Scheme Discount Percentage
    const gstAmount = parseFloat(detail.gst_code) || 0; // GST Amount (already calculated)

    // Calculate base total
    const baseTotal = ptr * quantity;

    // Calculate scheme discount amount
    const schemeDiscountAmount = (baseTotal * schemeDiscountPercentage) / 100;

    // Calculate discounted total (Base Total - Scheme Discount)
    const discountedTotal = baseTotal - schemeDiscountAmount;

    // Final total after scheme discount and adding GST
    const finalTotal = discountedTotal + gstAmount;

    return {
      baseTotal: baseTotal.toFixed(2), // PTR x Quantity
      schemeDiscountAmount: schemeDiscountAmount.toFixed(2), // Calculated Scheme Discount Amount
      gstAmount: gstAmount.toFixed(2), // GST Amount (already calculated)
      finalTotal: finalTotal.toFixed(2), // Final Total Amount
    };
  };

  return (
    <Box p={3} style={{ padding: isMobile ? '0.5rem' : '1rem', margin: '0' }}> {/* Adjusted left-right padding to zero */}
      <Typography variant={isMobile ? 'h5' : 'h4'} gutterBottom>
        Order Details - {orderId}
      </Typography>

      {/* Skeleton Loader for Order Details */}
      {loading ? (
        Array.from(new Array(3)).map((_, index) => (
          <Paper key={index} elevation={3} style={{ padding: '1rem', marginBottom: '1rem' }}>
            {/* Skeleton for Order Header */}
            <Skeleton variant="rectangular" height={40} width="60%" style={{ marginBottom: '0.5rem' }} />

            {/* Skeleton for Product Information */}
            <Skeleton variant="rectangular" height={20} width="100%" style={{ marginBottom: '1rem' }} />

            {/* Skeleton for Calculation Summary */}
            <Box display="flex" justifyContent="space-between" style={{ margin: '1rem 0' }}>
              {/* Skeletons for Calculation Fields */}
              <Skeleton variant="text" width="20%" height={30} />
              <Skeleton variant="text" width="20%" height={30} />
              <Skeleton variant="text" width="20%" height={30} />
              <Skeleton variant="text" width="20%" height={30} />
            </Box>
          </Paper>
        ))
      ) : orderDetails.length === 0 ? (
        <Typography variant="h6" color="textSecondary">
          No order details found.
        </Typography>
      ) : (
        orderDetails.map((detail) => {
          // Calculate the values using the helper function
          const { baseTotal, schemeDiscountAmount, gstAmount, finalTotal } = calculateDetails(detail);

          return (
            <Paper
              key={detail.id}
              style={{
                padding: isMobile ? '1rem' : '1.5rem', // Responsive padding
                marginBottom: isMobile ? '1rem' : '1.5rem',
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                borderRadius: '10px',
                backgroundColor: '#fff',
                marginLeft: '0',
                marginRight: '0',
              }}
            >
              {/* Product Name */}
              <Box
                style={{
                  backgroundColor: '#4a90e2',
                  padding: isMobile ? '0.5rem 1rem' : '0.75rem 1.5rem',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  marginBottom: '1rem',
                }}
              >
                <Typography variant={isMobile ? 'h6' : 'h5'} style={{ fontWeight: 'bold', color: 'white' }}>
                  {detail.prod_name_to_display}
                </Typography>
              </Box>

              {/* Product Information Section */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                style={{
                  backgroundColor: '#f5f5f5',
                  padding: isMobile ? '0.5rem 1rem' : '0.75rem 1.5rem',
                  borderRadius: '6px',
                  marginBottom: '1rem',
                }}
              >
                <Typography variant="body2" style={{ fontWeight: 'bold', color: '#333' }}>
                  Quantity: {detail.qty}
                </Typography>
                <Typography variant="body2" style={{ fontWeight: 'bold', color: '#333' }}>
                  PTR: ₹{detail.product_mrp}
                </Typography>
                <Typography variant="body2" style={{ fontWeight: 'bold', color: '#333' }}>
                  Scheme Discount: {detail.discount_percentage || '0'}%
                </Typography>
              </Box>

              {/* Single-Line Calculation Summary */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent={isMobile ? 'space-around' : 'space-between'} // Adjust layout for mobile
                flexWrap={isMobile ? 'wrap' : 'nowrap'} // Wrap content on small screens
                padding="1rem"
                style={{
                  backgroundColor: '#f0f0f0',
                  borderRadius: '6px',
                }}
              >
                {/* Base Total */}
                <Box display="flex" alignItems="center">
                  <ShoppingCartIcon fontSize="small" style={{ marginRight: '5px', color: '#4caf50' }} />
                  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                    ₹{baseTotal}
                  </Typography>
                </Box>

                {/* Scheme Discount */}
                <Box display="flex" alignItems="center">
                  <DiscountIcon fontSize="small" style={{ marginRight: '5px', color: '#ff5722' }} />
                  <Typography variant="body2" style={{ fontWeight: 'bold', color: '#ff5722' }}>
                    -₹{schemeDiscountAmount}
                  </Typography>
                </Box>

                {/* GST Amount */}
                <Box display="flex" alignItems="center">
                  <AttachMoneyIcon fontSize="small" style={{ marginRight: '5px', color: '#ffc107' }} />
                  <Typography variant="body2" style={{ fontWeight: 'bold', color: '#ffc107' }}>
                    ₹{gstAmount}
                  </Typography>
                </Box>

                {/* Final Total */}
                <Box display="flex" alignItems="center">
                  <AttachMoneyIcon fontSize="small" style={{ marginRight: '5px', color: '#4a90e2' }} />
                  <Typography variant="body2" style={{ fontWeight: 'bold', color: '#4a90e2' }}>
                    ₹{finalTotal}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          );
        })
      )}

      {/* Grand Total Section */}
      {loading ? (
        <Paper
          elevation={3}
          style={{
            marginTop: '2rem',
            padding: '1rem',
          }}
        >
          <Skeleton variant="rectangular" height={40} width="50%" style={{ margin: '0 auto' }} />
        </Paper>
      ) : (
        <Paper
          elevation={3}
          style={{
            marginTop: '2rem',
            padding: isMobile ? '1rem' : '1.5rem',
            backgroundColor: '#e0f7fa',
            border: '1px solid #0097a7',
            borderRadius: '10px',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
            textAlign: 'right',
            marginLeft: '0',
            marginRight: '0',
          }}
        >
          <Typography variant="h6" style={{ fontWeight: 'bold', color: '#00796b' }}>
            Grand Total: ₹{grandTotal}
          </Typography>
        </Paper>
      )}
    </Box>
  );
};

export default OrderDetail;
