import React from 'react';
import { List, ListItem, ListItemText, Divider, Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Sidebar = ({ onClose, onLogout, customer }) => {
  return (
    <Box>
      <Box p={2} style={{ backgroundColor: '#4a90e2', color: 'white', borderRadius: '4px' }}>
        <Typography variant="h6">Customer Details</Typography>
        <Typography variant="body1"><strong>Name:</strong> {customer.customer_name}</Typography>
        <Typography variant="body1"><strong>Address:</strong> {customer.address}</Typography>
        <Typography variant="body1"><strong>GSTIN:</strong> {customer.GSTIN}</Typography>
      </Box>
      <List>
        <Divider />
        <ListItem button component={Link} to="/" onClick={onClose}>
          <ListItemText primary="Home" />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/search" onClick={onClose}>
          <ListItemText primary="Search Product" />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/cart" onClick={onClose}>
          <ListItemText primary="Cart" />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/orders" onClick={onClose}>
          <ListItemText primary="View Orders" />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/ledger" onClick={onClose}>
          <ListItemText primary="View Ledger" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => { onLogout(); onClose(); }}>
          <ListItemText primary="Logout" />
        </ListItem>
        <Divider />
      </List>
    </Box>
  );
};

export default Sidebar;
