import React, { useState, useEffect } from 'react';
import { TextField, List, IconButton, Box, Paper, Typography, Snackbar, Divider, Button, useMediaQuery,Skeleton } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

const ProductSearch = ({ onAddToCart, cartItems = [] }) => {
  const [query, setQuery] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [qty, setQty] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state for skeleton loader

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Import the useMediaQuery function

  useEffect(() => {
    const fetchProducts = async () => {
      if (query.length >= 3) {
        setLoading(true); // Start loading when search begins
        try {
          const response = await axios.get(`https://avinandanserampore.com/api/search_products.php?query=${query}`);
          setFilteredProducts(response.data);
          setLoading(false); // Stop loading after fetching data
        } catch (error) {
          console.error('Error fetching products:', error);
          setLoading(false); // Stop loading in case of an error
        }
      } else {
        setFilteredProducts([]);
      }
    };

    fetchProducts();
  }, [query]);

  useEffect(() => {
    const initialQty = {};
    cartItems.forEach(item => {
      initialQty[item.product_id] = item.quantity;
    });
    setQty(initialQty);
  }, [cartItems]);

  const handleAddToCart = (product) => {
    const quantity = parseInt(qty[product.product_id], 10) || 0;
    if (quantity > 0) {
      onAddToCart({ ...product, quantity });
      setSnackbarOpen(true);
    }
  };

  const handleQtyChange = (productId, value) => {
    if (!isNaN(value) && value >= 0) {
      setQty({ ...qty, [productId]: value });
    }
  };

  const incrementQty = (productId) => {
    setQty({ ...qty, [productId]: (parseInt(qty[productId], 10) || 0) + 1 });
  };

  const decrementQty = (productId) => {
    setQty({ ...qty, [productId]: Math.max((parseInt(qty[productId], 10) || 0) - 1, 0) });
  };

  const isInCart = (productId) => {
    return cartItems.some(item => item.product_id === productId);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const getBackgroundColor = (stock) => {
    if (stock == 0) {
      return '#ffcccc'; // Red for out of stock
    } else if (stock < 20) {
      return '#fff0b3'; // Yellow for low stock
    } else {
      return '#e3f2fd'; // Blue for sufficient stock
    }
  };

  return (
    <Box padding={2} style={{ backgroundColor: '#f7f9fc', minHeight: '100vh' }}>
      {/* Fixed Search Box */}
      <Box position="sticky" top={0} zIndex={1000} style={{ backgroundColor: '#f7f9fc', padding: '10px 0' }}>
        <TextField
          label="Search Product"
          variant="outlined"
          fullWidth
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          margin="normal"
          style={{ backgroundColor: 'white', borderRadius: '5px' }}
        />
      </Box>

      {/* Product List with Skeleton Loader */}
      <List>
        {loading ? (
          Array.from(new Array(5)).map((_, index) => (
            <Paper key={index} elevation={3} style={{ padding: '1rem', marginBottom: '1rem' }}>
              <Skeleton variant="rectangular" height={30} width="60%" style={{ marginBottom: '0.5rem' }} />
              <Skeleton variant="rectangular" height={40} width="100%" style={{ marginBottom: '1rem' }} />
              <Skeleton variant="rectangular" height={30} width="100%" style={{ marginBottom: '0.5rem' }} />
            </Paper>
          ))
        ) : (
          filteredProducts.map((product) => {
            const stockIsZero = product.total_stock_qty == 0;
            return (
              <Paper
                elevation={3}
                style={{
                  marginBottom: '1rem',
                  transition: 'transform 0.3s',
                  border: isInCart(product.product_id) ? '2px solid green' : '1px solid #ccc',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  backgroundColor: getBackgroundColor(product.total_stock_qty), // Dynamic background color based on stock
                }}
                key={product.product_id}
              >
                <Box display="flex" flexDirection="column">
                  {/* Product Header */}
                  <Box padding="0.5rem 1rem">
                    <Typography variant="h6" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                      {product.product_name}
                    </Typography>
                  </Box>

                  {/* Quantity and Control Section */}
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexWrap="wrap"
                    padding="1rem"
                    style={{ backgroundColor: '#ffffff', borderRadius: '0px 0px 4px 4px' }}
                  >
                    <IconButton
                      onClick={() => decrementQty(product.product_id)}
                      style={{ color: '#ff5722', transition: 'transform 0.3s' }}
                      className="decrement-button"
                      disabled={stockIsZero}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <TextField
                      value={qty[product.product_id] || '0'}
                      onChange={(e) => handleQtyChange(product.product_id, e.target.value)}
                      style={{ width: '50px', margin: '0 10px' }}
                      inputProps={{ min: 0, style: { textAlign: 'center' }, readOnly: stockIsZero }}
                      margin="normal"
                      disabled={stockIsZero}
                    />
                    <IconButton
                      onClick={() => incrementQty(product.product_id)}
                      style={{ color: '#4caf50', transition: 'transform 0.3s' }}
                      className="increment-button"
                      disabled={stockIsZero}
                    >
                      <AddIcon />
                    </IconButton>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddToCart(product)}
                      startIcon={isInCart(product.product_id) ? <ChangeCircleIcon /> : <AddShoppingCartIcon />}
                      style={{ marginLeft: '10px' }}
                      disabled={stockIsZero}
                    >
                      {isInCart(product.product_id) ? 'Change Qty' : 'Add'}
                    </Button>
                  </Box>

                  {/* Divider */}
                  <Divider style={{ width: '100%' }} />

                  {/* Product Details in One Line */}
                  <Box
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center"
                    style={{
                      padding: '0.5rem 1rem',
                      backgroundColor: '#f5f5f5',
                      borderBottomLeftRadius: '4px',
                      borderBottomRightRadius: '4px',
                    }}
                  >
                    <Typography variant="body2" style={{ fontSize: isMobile ? '0.8rem' : '1rem', color: '#333' }}>
                      MRP: ₹{product.mrp}
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: isMobile ? '0.8rem' : '1rem', color: '#333' }}>
                      PTR: ₹{product.ptr}
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: isMobile ? '0.8rem' : '1rem', color: '#333' }}>
                      Scheme: {product.scheme || 'N/A'}
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: isMobile ? '0.8rem' : '1rem', color: '#333' }}>
                      Stock: {product.total_stock_qty}
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: isMobile ? '0.8rem' : '1rem', color: '#333' }}>
                      GST: {product.gst}%
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            );
          })
        )}
      </List>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Item quantity updated in cart"
      />
    </Box>
  );
};

export default ProductSearch;
