import React, { useEffect, useState } from 'react';
import { Box, Button, Paper, Typography, Pagination, Skeleton, Divider } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import VerifiedIcon from '@mui/icons-material/Verified';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ViewOrder = ({ customer }) => {
  const [orders, setOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // State to manage loading state

  useEffect(() => {
    if (customer && customer.customer_id) {
      fetchOrders(currentPage);
    }
  }, [customer, currentPage]);

  const fetchOrders = async (page) => {
    setLoading(true); // Set loading to true when fetching data
    try {
      const response = await axios.get('https://avinandanserampore.com/api/get_orders.php', {
        params: {
          customer_id: customer.customer_id,
          page: page,
        },
      });
      setOrders(response.data.orders || []);
      setTotalPages(response.data.total_pages || 1);
      setCurrentPage(response.data.current_page || 1);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error('Error fetching orders:', error);
      setOrders([]);
      setLoading(false); // Set loading to false if an error occurs
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box p={3} style={{ padding: 0, margin: 0 }}> {/* Remove left-right space by setting padding and margin to 0 */}
      <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#1976d2', margin: '1rem' }}>
        View Orders
      </Typography>
      
      {/* Skeleton Loader for Orders */}
      {loading ? (
        Array.from(new Array(5)).map((_, index) => (
          <Paper key={index} elevation={3} style={{ padding: '1rem', marginBottom: '1rem', width: '100%' }}> {/* Full width */}
            {/* Skeletons for Header and Body */}
            <Skeleton variant="text" height={40} width="60%" style={{ marginBottom: '0.5rem' }} />
            <Skeleton variant="text" height={20} width="80%" />
            <Skeleton variant="rectangular" height={40} style={{ marginTop: '1rem' }} />
          </Paper>
        ))
      ) : (
        orders.length === 0 ? (
          <Typography variant="h6" color="textSecondary" style={{ margin: '1rem' }}>
            No orders found.
          </Typography>
        ) : (
          orders.map((order) => (
            <Paper
              key={order.order_id}
              elevation={3}
              style={{
                padding: '1rem',
                marginBottom: '1rem',
                width: '100%', // Full width
                backgroundColor: '#f0f4c3',
                borderLeft: '6px solid #1976d2',
                position: 'relative',
                overflow: 'hidden',
                boxSizing: 'border-box',
              }}
            >
              {/* Order Header Section */}
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} style={{ margin: '0 1rem' }}>
                <Box display="flex" alignItems="center">
                  <ReceiptIcon fontSize="large" style={{ marginRight: '8px', color: '#1976d2' }} />
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    #:{order.order_id}
                  </Typography>
                </Box>
                <Button
                  component={Link}
                  to={`/order/${order.order_id}`}
                  variant="contained"
                  color="primary"
                  startIcon={<VisibilityIcon />}
                  style={{ backgroundColor: '#388e3c', fontWeight: 'bold' }}
                >
                  View 
                </Button>
              </Box>

              <Divider style={{ margin: '0.5rem 0' }} />

              {/* Order Details Section */}
              <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" style={{ margin: '0 1rem' }}>
                {/* Order Date */}
                <Box display="flex" alignItems="center">
                  <CalendarTodayIcon fontSize="small" style={{ marginRight: '5px', color: '#ffb74d' }} />
                  <Typography variant="body1">
                    Date: {order.order_date}
                  </Typography>
                </Box>

                {/* Order Status */}
                <Box display="flex" alignItems="center" style={{ marginTop: '0.5rem' }}>
                  <VerifiedIcon fontSize="small" style={{ marginRight: '5px', color: order.status === 'PLACED' ? '#388e3c' : '#d32f2f' }} />
                  <Typography variant="body1" style={{ color: order.status === 'PLACED' ? '#388e3c' : '#d32f2f' }}>
                    Status: {order.status}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          ))
        )
      )}

      {/* Pagination Component */}
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        size="large"
        shape="rounded"
        style={{ marginTop: '1rem', marginBottom: '1rem' }} // Adjust margins for spacing
      />
    </Box>
  );
};

export default ViewOrder;
